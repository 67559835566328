.restart-btn {
  background: #c00;
  color: white;
  font-weight: bold;
  margin: 0;
}

.restart-text {
  font-size: 14px;
  margin: 0 0 10px 0;
  color: #444;
}

.table {
  font-size: small;
  margin: 0px;
  padding: 0px 0px 0px 0px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border-color: #e5e5e5;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border-radius: 4px;
}

.table th {
  font-weight: normal;
  background-color: rgb(119, 162, 219, 0.7);
  padding: 5px 5px;
  text-transform: uppercase;
  text-align: left;
}

.table td {
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
}

.table tr:hover {
  background: #d8d8d8;
}
