.contract-form {
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 8px 8px 15px 8px;
}

.dashboard-form {
  margin: 20px 10px;
  background-color: white;
  padding: 10px 8px 15px 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -1px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border-color: #e5e5e5;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
}

.contract-form-title {
  margin: -18px 0 4px 4px;
  background: white;
  display: table;
  padding: 0 3px;
  color: red;
  font-weight: bold;
}

.form-block,
.form-inline {
  margin-bottom: 10px;
}

.label-inline {
  display: inline-block;
  width: 190px;
  padding-bottom: 10px;
}

.value {
  display: inline-block;
  vertical-align: top;
}

input[readonly] {
  background: #eee;
}

input {
  border: 1px solid #bbb;
}

.form-inline > div {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
