.commitment-container {
  padding: 15px 0 8px;
}

.commitment-btn {
  background: rgb(239, 239, 239);
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  color: black;
  text-decoration: none;
}

.commitment-btn:hover {
  background: rgb(225, 225, 225);
}

.number-input-mr10 {
  margin-left: -12px;
  margin-right: 10px;
}
