.proforma-left {
  vertical-align: top;
  display: inline-block;
  width: 500px;
}

.proforma-right {
  margin-left: 10px;
  vertical-align: top;
  display: inline-block;
  width: 55%;
}

.agreement-mode {
  float: right;
  margin-right: 10px;
}

a {
  color: #345995;
}

.agreement-mode .selected {
  font-weight: 800;
}

.agreement-mode .disabled {
  color: grey;
  cursor: default;
}

.message {
  margin: 10px 0;
  padding: 8px;
  border: 1px solid #77f;
  color: #007;
}

.whc {
  color: #345995;
}

.error {
  color: black;
  background: #04d4e0;
  margin: 0 0 20px 0;
  padding: 5px;
}

.warning {
  color: #000;
  background: transparent;
  margin: 10px 0;
  padding: 5px;
  display: flex;
  gap: 3px;
}

.number-input {
  display: inline-block;
}

.number-input-ml10 {
  margin-left: 10px;
}

.number-input span {
  display: inline-block;
  margin-right: 4px;
  color: black;
}

.number-input input {
  width: 100px;
}

.label-with-tooltip .tooltip {
  white-space: pre;
}

.checkbox input {
  vertical-align: bottom;
  margin: 0 20px 2px 3px;
}

.btn {
  margin: 8px 0;
  padding: 4px 8px;
  font-weight: bold;
}

.processing {
  margin: 0 20px;
}

.form-line {
  margin: 8px 8px 0 0;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}
