.tabs {
  padding: 10px 0px;
}

.tabs button {
  outline: none;
  border: none;
  background: white;
  padding: 6px 30px;
}

.tabs button::after {
  display: block;
  content: '';

  transform: scaleX(1);
  transition: transform 250ms ease-in-out 0s;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0px;
  z-index: 999999;
}

.tabs button.selected {
  background: white;
  border: 10px solid;
  border-width: 4px;
  border-image-source: linear-gradient(90deg, #ca2bf7 0%, #04d4e0 100%);
  border-image-slice: 1;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
