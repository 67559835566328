.container {
  margin: 0 auto;
}

.dashboard-background {
  background: #eee;
}

.container-white {
  background: white;
}

.container .tabs button {
  padding: 4px 8px;
}

.popup-section-title {
  font-weight: 600;
  background-color: #bababa;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 1px 2px;
}

.button-primary[disabled]:hover {
  background-color: #ca2bf7;
  color: white;
}

.button-primary:hover {
  color: #ca2bf7;
  background-color: white;
}

.button-primary {
  background-color: #ca2bf7;
  color: white;
  border: #ca2bf7 1px solid;
  text-decoration: none;
}

.button-medium {
  font-size: 14px;
  padding: 5px 12px;
  border-radius: 4px;
}
